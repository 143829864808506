<template>
  <div style="padding: 20px">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="充值金额" prop="amount">
        <el-input v-model="ruleForm.amount" />
      </el-form-item>
      <el-form-item label="快捷选择" style="margin-top: 30px">
        <div class="grids">
          <div
            :class="choice === index ? 'grids-finish' : 'grids-item'"
            @click="choiceFun(item, index)"
            v-for="(item, index) in list"
            :key="index"
          >
            <span style="font-size: 26px">{{ item }}</span>
            <span style="margin-left: 5px">元</span>
          </div>
        </div>
      </el-form-item>
    <el-form-item style="margin-top: 50px">
        <el-button type="primary" @click="submitForm('ruleForm')">支付订单</el-button>
        <el-button @click="remFun">放弃支付</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>  
<script>
import myBus from '@/plugins/myBus.js'
export default {
  data() {
    return {
      list: [10, 50, 100, 500, 1000],
      choice: null,
      ruleForm:{
        amount: "",
      },
     
      rules: {
        amount: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
          { pattern: /^\d+(\.\d{1,2})?$/, message: "请输入正确金额" },
        ],
      }
    }

  },
  watch:{
    'ruleForm.amount'(val) {
        const resIt = this.list.findIndex((item)=>{
            return item == val
        })
        resIt>=0?this.choice = resIt:this.choice = null
			}
  },
  methods: {
    choiceFun(item, index) {
      this.choice = index;
      this.ruleForm.amount = item
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('getPay',2)
          myBus.$emit('qr',this.ruleForm.amount)//像二维码发送金额
        } else {
          return false;
        }
      });
    },
    //取消按钮
    remFun(){
      ////console.log('取消')
      this.$emit('setClose',false)
    }
  }
}

</script>
  
<style lang="scss">
.grids {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 20px;

  &-item {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }

  &-item:hover {
    background: #dedfe0;
  }

  &-finish {
    background: #409eff;
    color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>